import * as React from "react";
import * as ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { TextArea, RadioButton, RadioGroup } from "@progress/kendo-react-inputs";
import { FormTextArea, FormInput, feedbackValidator, nameValidator, emailValidator, FormRadioGroup } from "./FormComponent";
import {GetToken} from '../../common/getToken'
import Pageloader from "../../common/pageloader/Pageloader";
import axios from "axios";
import Swal from "sweetalert2";
import { FaCommentDots, FaTimes, IconName } from "react-icons/fa";

const confirmationData = [
  {
    label: "Feedback for Survey",
    value: "0",
  },
  {
    label: "Feedback for Platform",
    value: "1",
  },
];

const radioGroupValidator = (value) => (!value ? "Type of Response mandatory" : "");

const FeedbackForm = () => {
  /* Add title */
  document.title = "Openeyes Surveys";

  /* get REACT_APP_API_URL from .env file */
  const { REACT_APP_API_URL,REACT_APP_REQUEST_KEY, } = process.env;
  const { REACT_APP_BASE_URL } = process.env;

  /* variable declarations */
  const [loading, setLoading] = useState(false);

  const [toggleSuccess, setToggleSuccess] = useState("");
  const [toggleError, setToggleError] = useState("");

  const onToggleSuccess = (message) => {
    setToggleSuccess(message);
    if (message != "") {
      setTimeout(() => {
        setToggleSuccess("");
      }, 4000);
    }
  };

  const onToggleError = (message) => {
    setToggleError(message);
    if (message != "") {
      setTimeout(() => {
        setToggleError("");
      }, 4000);
    }
  };

  const handleSubmit = (dataItem) => {
    console.log(dataItem);
    setLoading(true);
    axios
      .post(`${REACT_APP_API_URL}/publicFeedbackRequest`, {
        isSurvey: 1,
        name: dataItem.name,
        email: dataItem.email,
        feedback: dataItem.comments,
        survey_id: "platform-feedback",
        platform_id: "b1662fda-0740-11ec-af38-1908de41ac9c",
      },{
        headers: {
          Authorization: GetToken(),
          RequestKey: REACT_APP_REQUEST_KEY,
          
        },
      })
      .then(async (response) => {
        if (response.status) {
          onToggleSuccess("Thank you for your feedback");
          setLoading(false);
        }
      })
      .catch((error) => {
        onToggleError("Something is wrong.");
        setLoading(false);
      });
  };

  return (
    <>
      <div className="feeback_chat_popup">
        {loading ? (
          <></>
        ) : (
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps) => (
              <FormElement>
                <fieldset className={"k-form-fieldset"}>
                  <input type="checkbox" id="check" />
                  <label className="chat-btn" htmlFor="check">
                    {/* <FaCommentDots className="comment" /> */}
                    <span>
                      Feedback
                    </span>
                    {/* <FaTimes className="close" /> */}
                  </label>
                  <div className="chat_popup">
                    <div className="wrapper">
                      <div className="header">
                        <h6 className="float-start">Feedback about the Platform</h6>
                        <label className="chat-btn float-end" htmlFor="check">
                          <FaTimes className="close" />
                        </label>
                        <div className="clearfix"></div>
                      </div>
                      <div className="p-3 pb-0 pt-0 mt-1">
                        <span className="instruction">
                          We are always hungry for suggestions and feedbacks. Your suggestions are most valuable to us to making this platform even more
                          user friendly!
                        </span>
                      </div>
                      <div className="chat-form">
                        <Field
                          className="form-control"
                          placeholder="Enter your name"
                          id={"name"}
                          name={"name"}
                          component={FormInput}
                          validator={nameValidator}
                        />

                        <Field
                          className="form-control"
                          placeholder="Enter your email"
                          id={"email"}
                          name={"email"}
                          component={FormInput}
                          validator={emailValidator}
                        />

                        <Field
                          className="form-control"
                          placeholder="Enter your feedback here"
                          id={"comments"}
                          name={"comments"}
                          component={FormTextArea}
                          validator={feedbackValidator}
                          rows={3}
                        />

                        <Button themeColor={"primary"} type={"submit"} className="btn btn-grad btn-grad-blue" disabled={!formRenderProps.allowSubmit}>
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </FormElement>
            )}
          />
        )}
      </div>

      {toggleSuccess != "" ? (
        <div className="alert alert-success alert-dismissible fade show" role="alert">
          {toggleSuccess}
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setToggleSuccess("")}></button>
        </div>
      ) : (
        <></>
      )}

      {toggleError != "" ? (
        <div className="alert alert-danger alert-dismissible fade show" role="alert">
          {toggleError}
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setToggleError("")}></button>
        </div>
      ) : (
        <></>
      )}

      {/* }
      <Header />
      <div className="dashboard-main feedback_form">
        <div className="container-fluid">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-xl-6">
              <div className="form-account bg-white shadow-sm">
                {loading ? (
                  <Pageloader/>
                ) : (
                  <Form
                    onSubmit={handleSubmit}
                    render={(formRenderProps) => (
                      <FormElement>
                        <fieldset className={"k-form-fieldset"}>
                       
                          <div>
                            <h4>Fill Your Feedback</h4>
                          </div>

                       
                          <div className="mb-3 text-start">
                            <Field
                              id={"feedbackType"}
                              name={"feedbackType"}
                              label={"Type of Feedback :"}
                              component={FormRadioGroup}
                              data={confirmationData}
                              layout={"horizontal"}
                              validator={radioGroupValidator}
                            />
                          </div>

                          <Field id={"comments"} name={"comments"} label={"Feedback :"} component={FormTextArea} validator={feedbackValidator} />
                          <span className={"k-form-separator"} />
                          <div className="col-12 mt-3 text-center">
                            <Button themeColor={"primary"} type={"submit"} className="btn-grad btn-grad-blue" disabled={!formRenderProps.allowSubmit}>
                              Submit Feedback
                            </Button>
                          </div>
                        </fieldset>
                      </FormElement>
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
       { */}
    </>
  );
};

export default FeedbackForm;
