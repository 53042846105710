import Cookies from "js-cookie";

export const GetToken = () => {
  var token = null;

  var token = Cookies.get("AuthToken");

  if (token) {
  } else {
    sessionStorage.removeItem("appLoginSession");
    localStorage.removeItem("appLocalUserToken");
  }

  if (sessionStorage.getItem("appLoginSession")) {
    token = sessionStorage.getItem("appLoginSession");
  } else if (localStorage.getItem("appLocalUserToken")) {
    token = JSON.parse(localStorage.getItem("appLocalUserToken")).token;
  }
  if (token) {
    return "Bearer " + token;
  } else {
    return false;
  }
};
