import Swal from "sweetalert2";

export const Error = (errorCode, message) => {
  if (errorCode === 404) {
    return Swal.fire({
      title: "Oops!",
      text: message,
      icon: "error",
      showConfirmButton: false,
      showCancelButton: false,
      timer: 5000,
      timerProgressBar: true,
    });
  } else if (errorCode === 422) {
    return Swal.fire({
      title: "Oops!",
      text: message,
      icon: "error",
      showConfirmButton: false,
      showCancelButton: false,
      timer: 5000,
      timerProgressBar: true,
    });
  } else if (errorCode === 401) {
    Swal.fire({
      title: "Oops!",
      text: "Unauthenticated Access",
      icon: "error",
      showConfirmButton: false,
      showCancelButton: false,
      timer: 5000,
      timerProgressBar: true,
    });
    // window.location = "/login";
    window.location = "/";
  } else if (errorCode === 123) {
    return Swal.fire({
      // title: "error",
      title: message,
      icon: "warning",
      showConfirmButton: false,
      showCancelButton: false,
      timer: 5000,
      timerProgressBar: true,
    });
  } else if (errorCode === 444) {
    return Swal.fire({
      title: message,
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      } else {
        window.location.reload();
      }
    });
  } else {
    return Swal.fire({
      title: "Oops!",
      text: "Something went wrong",
      icon: "error",
      showConfirmButton: false,
      showCancelButton: false,
      timer: 5000,
      timerProgressBar: true,
    });
  }
};
