export const GetUser = (props) => {
  if (localStorage.getItem("appLocalUserData")) {
    return JSON.parse(window.atob(localStorage.getItem("appLocalUserData")));
  } else {
    sessionStorage.removeItem("appLoginSession");
    localStorage.removeItem("appLocalUserToken");

    //props.history("/login");
  }
};
