import axios from "axios";
import React from "react";
import Swal from "sweetalert2";
import { Error } from "../../common/Error";
import { GetToken } from "../../common/getToken";
import { AiOutlineLogout } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Logout = ({ setLoading }) => {
  /* get REACT_APP_API_URL from .env file */
  const { REACT_APP_API_URL, REACT_APP_CookieURL,REACT_APP_REQUEST_KEY } = process.env;

  let history = useNavigate();

  const logout = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to logout?",
      //text: result.data.data,
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Logout",
      //timer: 5000,
      //timerProgressBar: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        var token = GetToken();
        axios
          .get(`${REACT_APP_API_URL}/logout`, {
            headers: {
              Authorization: token,
              RequestKey: REACT_APP_REQUEST_KEY,
            },
          })
          .then((result) => {
            if (result.status === 200) {
              setLoading(false);
              sessionStorage.removeItem("appLoginSession");
              localStorage.removeItem("appLocalUserToken");
              Cookies.remove("AuthToken", { domain: `${REACT_APP_CookieURL}` });
              window.location.href = "/Login";
              //history("/Login");
            }
          })
          .catch((err) => {
            setLoading(false);
            sessionStorage.removeItem("appLoginSession");
            localStorage.removeItem("appLocalUserToken");
            window.location.href = "/Login";
            //history("/Login");
          });
      }
    });
  };
  return (
    <a className="dropdown-item" href="/" onClick={(e) => logout(e)}>
      <AiOutlineLogout /> Logout
    </a>
  );
};

export default Logout;
