import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
//import { lazy, Suspense } from "react";
//import '@progress/kendo-theme-default/dist/all.css';
import PrivateRoutes from "./PrivateRoutes";
import moment from "moment";
import Pageloader from "./components/common/pageloader/Pageloader";

import Cookies from "js-cookie";

import $ from "jquery";
import Header from './components/common/header/Header';
import Layout from './Layout';
// import Subscription from "./components/user/subscription/Subscription";
// import SurveySettings from "./components/admin/createSurvey/SurveySettings/SurveySettings";

// admin side
const Registration = lazy(() => import("./components/admin/registration/Registration"));
const Login = lazy(() => import("./components/admin/login/Login"));


const ForgotPassword = lazy(() => import("./components/admin/forgotPassword/ForgotPassword"));
const ForgotEmail = lazy(() => import("./components/admin/forgotPassword/ForgotEmail"));
const ResetPassword = lazy(() => import("./components/admin/forgotPassword/ResetPassword"));
const RequestRegister = lazy(() => import("./components/admin/registrationRequest/RequestRegister"));
const Dashboard = lazy(() => import("./components/admin/dashboard/Dashboard"));
const SurveyList = lazy(() => import("./components/admin/surveyList/SurveyList"));
const ManageUsers = lazy(() => import("./components/admin/manageusers/manageusers"));
const CreateSurvey = lazy(() => import("./components/admin/createSurvey/AddSurvey/CreateSurvey"));
const AddQuestions = lazy(() => import("./components/admin/createSurvey/AddQuestions/AddQuestions"));
const SurveySettings = lazy(() => import("./components/admin/createSurvey/SurveySettings/SurveySettings"));

const ViewSurvey = lazy(() => import("./components/admin/viewSurvey/ViewSurvey/ViewSurvey"));
const ViewQuestions = lazy(() => import("./components/admin/viewSurvey/ViewQuestions/ViewQuestions"));
const ViewSettings = lazy(() => import("./components/admin/viewSurvey/ViewSettings/ViewSettings"));

const Settings = lazy(() => import("./components/admin/profileSettings/Settings"));
const Users = lazy(() => import("./components/admin/userManagement/Users"));
const EditOrganization = lazy(() => import("./components/admin/profileSettings/EditOrganization"));
const PrimaryContact = lazy(() => import("./components/admin/profileSettings/PrimaryContact"));
const DemoGraphicsPage = lazy(() => import("./components/admin/profileSettings/DemoGraphicsPage"));
const ChangeEmailOtp = lazy(() => import("./components/admin/profileSettings/ChangeEmailOtp"));
const CategoryManagement = lazy(() => import("./components/admin/categoryManagement/CategoryManagement"));


// common
const PageNotFound = lazy(() => import("./components/common/PageNotFound"));
const HomePage = lazy(() => import("./components/common/home/HomePage"));
const InterMedia = lazy(() => import("./components/common/InterMedia"))

// user side
const Introduction = lazy(() => import("./components/user/introduction/Introduction"));
const Survey = lazy(() => import("./components/user/survey/Survey"));
const ThankYou = lazy(() => import("./components/user/thankYou/ThankYou"));
const MySurvey = lazy(() => import("./components/user/mysurvey/MySurvey"));
const SurveyNotFound = lazy(() => import("./components/user/introduction/SurveyNotFound"));
//const FeedbackForm = lazy(() => import("./components/user/feedback/FeedbackForm"));
const PublicSurvey = lazy(() => import("./components/user/publicSurvey/PublicSurveyList"));

const DataRequest = lazy(() => import("./components/user/dataRequest/DataRequest"));
const DataRequestStatus = lazy(() => import("./components/user/dataRequest/DataRequestStatus"));
const DataRequestHistory = lazy(() => import("./components/user/dataRequest/DataRequestHistory"));

const Delivery = lazy(() => import("./components/user/delivery/Delivery"));
const Poll = lazy(() => import("./components/user/poll/Poll"));

function App() {
  // window.onbeforeunload = function() {
  //   sessionStorage.removeItem("appLoginSession");
  //   localStorage.removeItem("appLocalUserToken");
  // };
  const auth = () => {
    var token = null;

    var token = Cookies.get("AuthToken");

    if (token) {
    } else {
      sessionStorage.removeItem("appLoginSession");
      localStorage.removeItem("appLocalUserToken");
    }

    if (sessionStorage.getItem("appLoginSession")) {
      return true;
    } else if (localStorage.getItem("appLocalUserToken")) {
      var sessionDate = JSON.parse(localStorage.getItem("appLocalUserToken")).date;
      var diff = moment().startOf("day").diff(moment(sessionDate).startOf("day"), "days");
      if (diff >= 0 && diff < 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  // const body = document.querySelector("body");
  //   body?.style.setProperty("--screen-height", $(window).height() + "px");

  // $(window).scroll(function () {
  //   if ($(this).scrollTop() > 50) {
  //     $(".common_head").addClass("fixed-me");
  //     $(".black_logo").css("display", "block");
  //     $("#nav-logo-id").css("display", "none");
  //   } else {
  //     $(".common_head").removeClass("fixed-me");
  //     $("#nav-logo-id").css("display", "block");
  //     $(".black_logo").css("display", "none");
  //   }
  // });

  $(document).ready(function () {
    if (screen.width <= 1200) {
      $(".usp_wrap .usp_wrap_inner").click(function () {
        $(".usp_wrap_inner_desc").removeClass("active");
        $(".usp_wrap_inner").find(".usp_desc").slideUp(300);
        $(this).find(".usp_desc").slideToggle(300);
      });
    }
    $(document).click(function (event) {
      var clickover = $(event.target);
      if (!clickover.hasClass("navbar-collapse")) {
        $(".navbar-collapse").removeClass("show");
      }
    });

  });

  $('.k-rating-item').hover(function (e) {
    $(this).attr("title", "");
  });


  const isAuthenticated = auth();

  return (
    <div className="App">
      <Suspense
        fallback={
          <div className='fullpage-loader'><Pageloader /></div>
        }>
        <Router>
          <Routes>
            <Route element={<PrivateRoutes isAuth={isAuthenticated} />}  >
              <Route exact path="/Dashboard" element={<Dashboard />} />
              <Route exact path="/SurveyList" element={<SurveyList />} />
              <Route exact path="/ManageUsers" element={<ManageUsers />} />
              <Route exact path="/CreateSurvey" element={<CreateSurvey />} />
              <Route exact path="/CreateSurvey/:surveyId" element={<CreateSurvey />} />
              <Route exact path="/AddQuestions/:surveyId/:survey_name" element={<AddQuestions />} />
              <Route exact path="/AddQuestions/:surveyId/:survey_name/:page_id" element={<AddQuestions />} />
              <Route exact path="/SurveySettings/:surveyId" element={<SurveySettings />} />
              <Route exact path="/SurveySettings/:surveyId/:settings" element={<SurveySettings />} />
              <Route exact path="/Settings" element={<Settings />} />
              {/* <Route exact path="/Settings/:user_id/:id" element={<Settings />} /> */}
              <Route exact path="/Users" element={<Users />} />
              <Route exact path='/CategoryManagement' element={<CategoryManagement />} />
              <Route exact path="/ViewSurvey" element={<ViewSurvey />} />
              <Route exact path="/ViewSurvey/:surveyId" element={<ViewSurvey />} />
              <Route exact path="/ViewQuestions/:surveyId/:survey_name" element={<ViewQuestions />} />
              <Route exact path="/ViewQuestions/:surveyId/:survey_name/:page_id" element={<ViewQuestions />} />
              <Route exact path="/ViewSettings/:surveyId" element={<ViewSettings />} />
              <Route exact path="/ViewSettings/:surveyId/:settings" element={<ViewSettings />} />
              <Route exact path="/EditOrganization/:orgId" element={<EditOrganization />} />
              <Route exact path="/PrimaryContact/:orgId" element={<PrimaryContact />} />
              <Route exact path="/DemoGraphicsPage/:orgId" element={<DemoGraphicsPage />} />
              <Route exact path="/VerifyEmail/:token/:userId" element={<ChangeEmailOtp />} />
            </Route>
            {/* admin */}
            <Route path="/" element={<Layout />}>
              <Route exact path="" element={<PublicSurvey />} />
              <Route exact path="/Login" element={<Login />} />
              <Route exact path="/Registration" element={<Registration />} />
              <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
              <Route exact path="/ForgotEmail" element={<ForgotEmail />} />
              <Route exact path="/ResetPassword/:userId" element={<ResetPassword />} />
              <Route exact path="/RequestRegister/:userId" element={<RequestRegister />} />
              <Route exact path="/PageNotFound" element={<PageNotFound />} />
            </Route>
            {/* user */}
            <Route exact path="/:companyName/Introduction/:surveyId" element={<Introduction />} />
            <Route exact path="/:companyName/MySurvey" element={<MySurvey />} />
            {/* <Route exact path="/:companyName/FeedbackForm" element={<FeedbackForm />} /> */}
            <Route exact path="/:companyName/Survey/:surveyId/:userToken" element={<Survey />} />
            <Route exact path='/SurveyNotFound' element={<SurveyNotFound />} />

            <Route exact path="/publicSurvey" element={<PublicSurvey />} />


            <Route exact path="/DataRequest" element={<DataRequest />} />
            <Route exact path="/DataRequestHistory" element={<DataRequestHistory />} />
            <Route exact path="/DataRequestStatus" element={<DataRequestStatus />} />


            <Route exact path="/:companyName/ThankYou/:surveyId" element={<ThankYou />} />

            {/* common */}

            <Route exact path="/MySurvey" element={<MySurvey />} />

            <Route exact path="/:companyName/Delivery/:surveyId/:userToken" element={<Delivery />} />
            <Route exact path="/:companyName/Poll/:surveyId/:userToken" element={<Poll />} />

            <Route exact path="/" element={<Login />} />

            <Route exact path="/*" element={<PageNotFound />} />
            <Route exact path='/InterMedia' element={<InterMedia />} />


          </Routes>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
