import React from "react";
import Header from "../src/components/common/header/Header";
import Footer from "../src/components/common/footer/Footer";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
