import React from "react";
import FeedbackForm from "../feedback/FeedbackForm";
const Footer = () => {
  return (
    <footer className="footermobile">
      <div className="container-fluid">
        <div className="row align-items-center d-flex">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-center text-md-start">
            © {new Date().getFullYear()}{" "}
            <a href="https://theopeneyes.com" target="_blank">
              OpenEyes Technologies Inc.
            </a>
          </div>
          {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-center text-md-end">
            email us at: <a href="mailto:info@theOpenEyes.com">info@theOpenEyes.com</a>
          </div> */}
          <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 text-center">
            <a href="https://theopeneyes.com/privacy-policy" target="_blank">
              Privacy Policy
            </a>{" "}
            |{" "}
            <a href="https://theopeneyes.com/terms-of-use" target="_blank">
              Terms of use
            </a>{" "}
            |{" "}
            <a href="https://theopeneyes.com/cookie-policy" target="_blank">
              Cookie Policy
            </a>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 text-center text-md-end">
            <FeedbackForm />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
