import React, { useEffect } from "react";
import {Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = ({ isAuth, component: Component, ...rest }) => {
  useEffect(() => {
    // Update the document title based on page
    const changeTitle = () => {
      let title = window.location.pathname;
      title = title.substring(title.indexOf("/") + 1);
      if (title.includes("/", 0)) title = title.substring(title.lastIndexOf("/") - 1, 0);
      if (title.includes("/", 0)) title = title.substring(title.lastIndexOf("/") - 1, 0);
      document.title = title + " - Openeyes Surveys";
    };

    changeTitle();
  });
  return (
     isAuth? <Outlet /> : <Navigate  to={{ pathname: "/" }} />
  );
};

export default PrivateRoutes;
